body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'sofia-pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --main-gold: #C79F27;
  --navbar-height: 70px;
  --page-width: 550px;
}

body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@viewport {
  width: device-width ;
  zoom: 1.0 ;
}


/* UNIVERSAL */

ul {
  list-style: none;
  padding-left: 0px
}

@media screen and (max-width:480px){
  .page {
    max-width: 500px;
    padding: 10px 0px
  }
}

.error-message {
  height: 1rem;
  font-style: italic;
  font-size: small;
  color:rgb(124, 2, 0)
}

.clickable,
button {
  cursor: pointer;
}

/* PAGES */

.auth-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.auth-page {
  margin-top: var(--navbar-height)
}

.auth-page form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* .auth-page input {
  margin-bottom: 1rem;
  padding: .5rem;
  width: 100%
} */

/* .auth-page button {
  margin-top: 1rem;
  padding: .5rem;
  border: 2px solid var(--main-gold);
  border-radius:3px;
  background-color: white;
  color: black;
  margin-right: 2.5rem;
} */

.auth-page button,
.auth-page input {
  height: 2rem;
  width: 300px;
}

.auth-page input {
  border: 1px solid rgb(183, 183, 183);
  border-bottom: 0px;
  box-sizing: border-box;
  padding: .5rem;
}

.auth-page button {
  border: 1px solid var(--main-gold);
  background-color:#f7f7f7;
  color: black;
}


.auth-page form *:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.auth-page form *:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.demo-login {
  margin-top: 2rem;
  border-radius: 3px;
}

.loading-overlay {
  z-index: 1;
  position: relative;
  width: 100%;
  opacity: 40%
}


.layout {
  width: 100%;
}

.navbar-container,
.auth-banner {
  background-color: var(--main-gold);
  width: 100%;
  height: var(--navbar-height);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
}

.navbar {
  width: var(--page-width);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.navbar button {
  margin: 1.5rem 0rem .5rem 0rem;
  padding: .5rem;
  border: 2px solid var(--main-gold);
  border-radius:3px;
  background-color: white;
  color: black;
  margin-right: 2.5rem;
}

.active {
  text-decoration: underline !important;
}
.inactive {
  text-decoration: none;
}

.navlink{
  margin: 0rem .2rem;
  padding:2rem;
  font-size: larger;
  color: black;

}

.page-container {
  margin-top: var(--navbar-height);
  width: 100%;
  display: flex;
  justify-content: center;
}


.ballot-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: var(--page-width);
  padding: 2rem;
  margin-bottom: var(--navbar-height)
}



.award-box {
  border: solid 2px rgb(255, 255, 255)
}

.nominee-box {
  cursor: pointer;
}

h3 {
  color: var(--main-gold)
}

.missing {
  border: solid 2px rgb(232, 125, 123)
}

.nominee-box {
  border-top: 1px solid #aaa;
  padding: 1rem;
}

.nominee-box * {
  margin: .2rem;
}



.selected {
  background-color: var(--main-gold);
}

.winner {
  background-color: rgb(136, 239, 136)
}

.incorrect-choice {
  background-color:#ff7e7e
}

.ballot-submit-bar-container {
  position: fixed;
  bottom: 0;
  left: 0; 
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(31, 31, 31);
  height: var(--navbar-height)
}

.ballot-submit-bar {
  width: var(--page-width);
  text-align: end;

}

.ballot-submit-bar button {
  margin-top: 1rem;
  padding: .5rem;
  border: 2px solid var(--main-gold);
  border-radius:3px;
  background-color: rgb(40,40,40);
  color: white;
  margin-right: 2.5rem;
}


/* GROUP BALLOT */

.group-ballot-nominee {
  border-top: 1px solid #aaa;
  box-sizing: border-box;
  padding: 0rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.group-ballot-nominee div {
  width:60%;
}

.group-ballot-nominee h4,
.group-ballot-nominee h5 {
  margin: .5rem;
}

.group-ballot-nominee p {
  width:30%;
  text-align: right;
}


/* GROUP PAGE */

.groups-page {
  min-width: 350px
}

.groups-page > div {
  border: 2px var(--main-gold) solid;
  padding: .5rem;
  margin: 1rem .5rem;
}

.group-search-result {
  border: 1px solid black;
  border-radius: 3px;
  margin: 1rem 0rem;
  padding: 0.5rem;
}

.group-tile i {
  width: 1rem;
  padding-right: .25rem;
}

.group-name {
  font-style:italic;
}

.share-box {
  display: flex;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-bottom: .5rem;
  margin-bottom: .5rem;
}


.group-tile table {
  width: 100%
}

.group-search-result li {
  font-size: small;
}

.groups-page button,
.join-groups-page button {
  padding: .5rem;
  height: 2rem;
  border: 2px solid var(--main-gold);
  border-radius:3px;
  background-color: white;
  color: black;
}

.groups-page form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.groups-page input {
  height: 2rem;
  font-size: medium;
  box-sizing: border-box;
  padding: .25rem;
  margin: .25rem 0rem;
}

.join-group button {
  margin-top: .5rem;
}

.join-groups-page h5,
.join-group h5 {
  margin-bottom: 0rem;
}
.join-groups-page ul,
.join-group ul {
  margin-top: 0.25rem;
}

